import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"

import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardText,
  MDBCardTitle,
  MDBView,
  MDBBtn,
  MDBIcon,
  MDBAnimation,
} from "mdbreact"
import Layout from "../components/layout"
import SEO from "../components/seo"

import PageHero from "../components/pageHero"

const ProjectsPage = ({ data }) => (
  <Layout>
    <SEO title="Projects" />

    <PageHero
      title="Projects"
      subtitle="A selection of my most recent projects."
    />

    <MDBContainer className="py-3 py-md-5">
      <MDBRow>
        {data.allPrismicProject.edges.map((document, index) => (
          <MDBCol
            size="12"
            //md="6"
            //xl="4"
            className="mb-4"
            key={document.node.id}
          >
            <MDBAnimation type="fadeIn" delay={"0.3" * index + "s"} reveal>
              <MDBCard className="card-zoom">
                <MDBRow>
                  <MDBCol md="6">
                    <Link
                      to={`/projects/${document.node.uid}`}
                      alt="View Project"
                    >
                      <MDBView waves className="projects">
                        <Img
                          fluid={
                            document.node.data.hero_img.localFile
                              .childImageSharp.fluid
                          }
                          alt={document.node.data.hero_img.alt}
                        />
                      </MDBView>
                    </Link>
                  </MDBCol>
                  <MDBCol md="6" middle>
                    <MDBCardBody>
                      <MDBRow>
                        <MDBCol size="12" className="order-md-last">
                          <Link
                            to={`/projects/${document.node.uid}`}
                            alt="View Project"
                          >
                            <div className="card-btn d-block d-md-none">
                              <MDBBtn color="secondary" tag="span">
                                <MDBIcon icon="folder-open" />
                              </MDBBtn>
                            </div>
                            <div className="d-none d-lg-block pt-3">
                              <MDBBtn size="md" color="secondary" tag="span">
                                <MDBIcon icon="folder-open" className="mr-2" />
                                View Project
                              </MDBBtn>
                            </div>
                            <div className="d-none d-md-block d-lg-none pt-3">
                              <MDBBtn size="sm" color="secondary" tag="span">
                                <MDBIcon icon="folder-open" className="mr-2" />
                                View Project
                              </MDBBtn>
                            </div>
                          </Link>
                        </MDBCol>

                        <MDBCol size="12">
                          <MDBCardTitle className="font-weight-bold" tag="h5">
                            {document.node.data.title.text}
                          </MDBCardTitle>
                          {/* <hr /> */}
                        </MDBCol>
                        <MDBCol size="12">
                          <MDBCardText>
                            {document.node.data.subtitle.text}
                          </MDBCardText>
                        </MDBCol>
                      </MDBRow>
                    </MDBCardBody>
                  </MDBCol>
                </MDBRow>
              </MDBCard>
            </MDBAnimation>
          </MDBCol>
        ))}
      </MDBRow>
    </MDBContainer>
  </Layout>
)

export const query = graphql`
  query ProjectsPageQuery {
    allPrismicProject {
      edges {
        node {
          id
          uid
          data {
            category
            title {
              text
            }
            subtitle {
              text
            }
            hero_img {
              alt
              localFile {
                id
                childImageSharp {
                  fluid(
                    maxHeight: 350
                    maxWidth: 600
                    cropFocus: CENTER
                    quality: 90
                  ) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default ProjectsPage
